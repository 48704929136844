import React from 'react'

import { auth} from '../../../Config/firebase'

import {Grid, Typography, Button} from '@mui/material'

import OnboardingNavbar from '../../../Components/Navbars/OnboardingNavbar'

import '../Styles/RefreshPage.css'

function RefreshPage() {

    const logout = () => {
        auth.signOut().then(() => {
            window.localStorage.clear()
            window.location.href = 'https://stripe.com/login'
        })
    }

    return (
        <>
            <OnboardingNavbar />
            <br />
            <div className = "RefreshPage">
                <Typography variant='h4' align='center'>
                    It looks like your session was interrupted
                </Typography>
                <br />
                <Typography variant='h5' align='center'>
                    Please click the button below to continue
                </Typography>
                <br />
                <Typography variant='h5' align='center'>
                    You will be taken back to Stripe to complete your account. Once you are done, please login again again and set up the remaining parts of your account.
                </Typography>
                <br />
                <br />
                <Grid container justifyContent='center'>
                    <Button variant='contained' color='primary' onClick={() => logout()}>
                        Continue
                    </Button>
                </Grid>
            </div>
        </>
    )

}

export default RefreshPage

