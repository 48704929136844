import React, { useState, useRef, useEffect } from 'react';
import { ref, set, get, db, auth, storage, DBTruckRoute, DBUserRoute } from '../../../Config/firebase';
import { update } from 'firebase/database'
import { ref as stRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { uid } from 'uid'
import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import OnboardingNavbar from '../../../Components/Navbars/OnboardingNavbar';
import LoadingScreen from '../../../Components/LoadingScreen';
import { Typography } from '@mui/material';

function CreateMenu() {

  const id = auth.currentUser.uid;

  const [isOwner, setIsOwner] = useState(false)
  const [isEmployee, setIsEmployee] = useState(false)

  const checkStatus = async() => {
    const ownerRef = ref(db, `${DBUserRoute}/Owners/${id}`)
    const employeeRef = ref(db, `${DBUserRoute}/Employees/${id}`)
    const isOwner  = await get(ownerRef)
    const isEmployee = await get(employeeRef)
    if(isOwner.exists()){
      setIsOwner(true)
    } else if(isEmployee.exists()){
      setIsEmployee(true)
      setParent(isEmployee.val().employeeOf)
    }
  }

  const [truckID, setTruckID] = useState('')
  const [truckName, setTruckName] = useState('')
  const [parent, setParent] = useState()

  useEffect(() => {
    if(window.location.pathname.split('/')[4] === undefined){
        setTruckID(window.location.pathname.split('/')[3])
    } else {
        setTruckID(window.location.pathname.split('/')[4])
    }
    get(ref(db, `${DBTruckRoute}/${truckID}`)).then((snapshot) => {
        const data = snapshot.val()
        if(data.truckName){
            setTruckName(data.truckName)
        } else {
            setTruckName('')
        }
    })
    checkStatus()
})

  const navigate  = useNavigate()

  const [loading, setLoading] = useState(false);

  const itemId = uid(7)

  const [fileAttached, setFileAttached] = useState(false)
  const [preview, setPreview] = useState(null)

  const uploadPhoto = async () => {
    const customMetadata = {
      itemId : itemId,
    }
    const storageRef = stRef(storage, `${DBTruckRoute}/${truckID}/MenuPhotos/${menuItem.category}/${menuItem.name}.png`);
    await uploadBytes(storageRef, menuItem.photo, { customMetadata }).then((snapshot) => {
        console.log('Uploaded a blob or file!');
    });
    const url = getDownloadURL(storageRef);
    console.log(url);
    return url;
  }

  const writeMenuItem = (url) => {
    const menuItemRef = ref(db, `${DBTruckRoute}/${truckID}/Menu/${menuItem.category}/${menuItem.name}`);
    set(menuItemRef, {
        name: menuItem.name,
        id: itemId,
        price: menuItem.price,
        category: menuItem.category,
        optionGroups: menuItem.optionGroups,
        photo: url,
  })}

  const updateStatus = () => {
    const truckRef = ref(db, `${DBTruckRoute}/${truckID}`)
    update(truckRef, {
      menuCreated: true,
    })
  }

  const [menuItem, setMenuItem] = useState({
    name: '',
    price: '',
    category: '',
    optionGroups: [
      {
        name: '',
        options: [{ label: '', price: '' }],
      },
    ],
    photo: '',
  });

  const nameInputRef = useRef(null);
  const priceInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const photoInputRef = useRef(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setMenuItem((prevMenuItem) => ({
      ...prevMenuItem,
      [name]: value,
    }));
  };

  const handleOptionChange = (groupIndex, optionIndex, event) => {
    const { name, value } = event.target;
    setMenuItem((prevMenuItem) => {
      const newOptionGroups = [...prevMenuItem.optionGroups];
      const newOptions = [...newOptionGroups[groupIndex].options];
      newOptions[optionIndex] = {
        ...newOptions[optionIndex],
        [name]: value,
      };
      newOptionGroups[groupIndex].options = newOptions;
      return {
        ...prevMenuItem,
        optionGroups: newOptionGroups,
      };
    });
  };

  const handleOptionGroupChange = (groupIndex, event) => {
    const { name, value } = event.target;
    setMenuItem((prevMenuItem) => {
      const newOptionGroups = [...prevMenuItem.optionGroups];
      newOptionGroups[groupIndex] = {
        ...newOptionGroups[groupIndex],
        [name]: value,
      };
      return {
        ...prevMenuItem,
        optionGroups: newOptionGroups,
      };
    });
  };

  const handleAddOptionGroup = (event) => {
    event.preventDefault();
    setMenuItem((prevMenuItem) => ({
      ...prevMenuItem,
      optionGroups: [
        ...prevMenuItem.optionGroups,
        {
          name: '',
          options: [{ label: '', price: '' }],
        },
      ],
    }));
  };

  const handleAddOption = (groupIndex, event) => {
    groupIndex = parseInt(groupIndex);
    event.preventDefault();
    setMenuItem((prevMenuItem) => {
      const newOptionGroups = [...prevMenuItem.optionGroups];
      const newOptions = [...newOptionGroups[groupIndex].options];
      newOptions.push({ label: '', price: '' });
      newOptionGroups[groupIndex].options = newOptions;
      return {
        ...prevMenuItem,
        optionGroups: newOptionGroups,
      };
    });
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMenuItem((prevMenuItem) => ({
        ...prevMenuItem,
        photo: file,
      }));
      setFileAttached(true)
    }
  };

  const resetToDefault = () => {
    // remove added options 
    const defaultOptionGroups = [
      {
        name: '',
        options: [{ label: '', price: '' }],
      },
    ];
    setMenuItem((prevMenuItem) => ({
      ...prevMenuItem,
      optionGroups: defaultOptionGroups,
    }));
    // reset input fields
    nameInputRef.current.value = '';
    priceInputRef.current.value = '';
    categoryInputRef.current.value = '';
    photoInputRef.current.value = '';
    // remove stored values
    setMenuItem((prevMenuItem) => ({
      ...prevMenuItem,
      name: '',
      price: '',
      category: '',
      photo: null,
    }));
  };
  
  const uploadAndAddAnother = async (event) => {
    event.preventDefault();
    resetToDefault();
    setLoading(true);
    uploadPhoto();
    const url = await uploadPhoto();
    writeMenuItem(url);
    setLoading(false);
  };

  const uploadAndFinish = async (event) => {
    try{
      event.preventDefault();
      setLoading(true);
      const url = await uploadPhoto();
      writeMenuItem(url);
      updateStatus();
      if(isOwner){
        navigate('/owner-dashboard')
      } else if(isEmployee){
        navigate(`/user/next-steps/${parent}/${truckID}`)
      }
    } catch (error) {
      console.log(error);
    }
    
  };

  useEffect(() => {
    if(!menuItem.photo){
      setPreview(null)
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      }
      reader.readAsDataURL(menuItem.photo);
    }
  })

  
  if(loading) {
    return(
      <LoadingScreen />
    )
  }

  return (
    <>
    <OnboardingNavbar />
    <br />
    <Typography variant='h4' align='center'>
      Time to add your menu items {truckName ? `to ${truckName}` : null}!
    </Typography>
    <div className="SetMenuItems">
      <div className="SetMenuItem">
      <form className = "SetMenuItem_Form">
      <label htmlFor="icon-button-file">
        <br />
        <Stack direction="row" spacing={1} alignItems="center">
          <input
            accept="image/*"
            id="icon-button-file"
            type="file"
            style={{ display: 'none' }}
            onChange={handlePhotoChange}
            ref={photoInputRef}
          />
          <label htmlFor="icon-button-file">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <PhotoCamera /> Upload Photo 
            </IconButton>
            {fileAttached ? <img src={preview} alt = 'preview' style={{
              width: '80%',
              maxWidth: '400px',
              borderRadius: '10px',
            }} />: null}
          </label>
        </Stack>
          </label>
          <p1><p>Item Details:</p>
            <p3><p>Add your item name </p></p3>
          </p1>
        <TextField
          label="Name"
          variant="outlined"
          name="name"
          style={{
            width: '80%',
            maxWidth: '400px',
          }}
          value={menuItem.name}
          onChange={handleInputChange}
          inputRef={nameInputRef}
        />
        <p><p3><p>Add your item price </p></p3></p>
        <TextField
          label="Price"
          variant="outlined"
          name="price"
          style={{
            width: '80%',
            maxWidth: '400px',
          }}
          value={menuItem.price}
          onChange={handleInputChange}
          inputRef={priceInputRef}
        />
        <p><p3><p>Add your item category [e.g. Sandwich / Drink] </p></p3></p>
        <TextField
          label="Category"
          variant="outlined"
          name="category"
          style={{
            width: '80%',
            maxWidth: '400px',
          }}
          value={menuItem.category}
          onChange={handleInputChange}
          inputRef={categoryInputRef}
        />
        {menuItem.optionGroups.map((optionGroup, groupIndex) => (
          <div key={groupIndex}>
            <p1>
              <p> Item Options Group: </p>
              <p3><p>Add groups of add-ons/options [e.g.Add-ons / Remove] </p></p3> 
            </p1>
            <TextField
              label="Option Group"
              variant="outlined"
              name="name"
              style={{
                width: '80%',
                maxWidth: '400px',
              }}
              value={optionGroup.name}
              onChange={(event) => handleOptionGroupChange(groupIndex, event)}
            />
            {optionGroup.options.map((option, optionIndex) => (
              <div key={optionIndex}>
                <p2>
                  <p> In Group Choice:</p>
                  <p3><p>Specify option name [e.g. Milk] & price [e.g. +5.00] </p></p3> 
                </p2>
                <TextField
                  label="Option name"
                  variant="outlined"
                  name="label"
                  style={{
                    width: '80%',
                    maxWidth: '400px',
                  }}
                  value={option.label}
                  onChange={(event) =>
                    handleOptionChange(groupIndex, optionIndex, event)
                  }
                />
                <p></p>
                <TextField
                  label="Option Price" 
                  variant="outlined"
                  name="price"  
                  style={{
                    width: '80%',
                    maxWidth: '400px',
                  }}
                  value={option.price}
                  onChange={(event) =>
                    handleOptionChange(groupIndex, optionIndex, event)
                  }
                />
              </div>
            ))}
                  <p></p>
            <Button
              variant="contained"
              onClick={(event) => handleAddOption(groupIndex, event)}
              style = {
                {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  width: "200px",

                }
              }
            >
              Add Option
            </Button>
          </div>
        ))}
              <p></p>
        <Button 
          variant="contained" 
          onClick={handleAddOptionGroup}
          style = {
            {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              width: "200px",
            }
          }
          >
          Add Options Group
        </Button>
        
      </form>
      <p></p>
      <Button 
        variant="contained" 
        onClick={uploadAndAddAnother}
        style = {
          {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            width: "300px",
          }
        }
        >
        Upload Item and add another
      </Button>
      <p>When Finished:</p>
      <Button 
        variant="contained" 
        onClick={uploadAndFinish}
        style = {
          {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            width: "300px"
          }
        }
        >
        Upload Item and finish
      </Button>
    </div>
    </div>
    </>
  );

}

export default CreateMenu;
