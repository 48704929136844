import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//WebApp Imports
import TruckList from './sections/Trucks/Trucks';
import Truck from './sections/Trucks/Truck/Truck';
import Map from './sections/Trucks/Components/Map/Map';

// Landing Page Imports 
import LandingPage from './sections/LandingPage/Pages/LandingPage'
import MerchantLanding from './sections/LandingPage/Pages/ForMerchants';
import FAQ from './sections/LandingPage/Pages/FAQ'
import NotFound from './sections/LandingPage/Pages/NotFound';

// Auth Imports
import Register from './sections/Auth/Register';
import OwnerLogin from './sections/Auth/OwnerLogin';
import EmployeeLogin from './sections/Auth/EmployeeLogin'
import RegisterEmployee from './sections/Auth/EmployeeRegister';
import ForgotPassword from './sections/Auth/ForgotPassword';

// Dashboard Imports
import TruckDashboard from './sections/Dashboard/Truck/Components/TruckDashboard'
import OwnerDashboard from './sections/Dashboard/Owner/OwnerDashboard';
import AddTruck from './sections/Dashboard/Owner/CreateTruck';

// Account Setup Imports
import AccountType from './sections/AccountSetup/RegistrationFlow/AccountType';
import StripeConnect from './sections/AccountSetup/RegistrationFlow/StripeConnect';
import SetTruckInfo from './sections/AccountSetup/RegistrationFlow/SetTruckInfo';
import CreateMenu from './sections/AccountSetup/RegistrationFlow/CreateMenu'
import NextSteps from './sections/AccountSetup/RegistrationFlow/NextSteps';

import RefreshPage from './sections/AccountSetup/RefreshPage/RefreshPage';
import DetailsNotSubmitted from './sections/AccountSetup/DetailsNotSubmitted';
import Completion from './sections/AccountSetup/Completion';

// Context Imports
import { AuthProvider } from './sections/Auth/Context/authContext';
import { ProtectedRoutes } from './Components/ProtectedRoutes';

// Payment Imports
import PaymentPage from './sections/Payment/Pages/PaymentPage';


function App() {

    if(process.env.REACT_APP_DEPLOY_TRUCKS_PAGE === 'true'){

    return (
        <AuthProvider>
        <Router>
            <Routes>

                {/*Truck Imports*/}
                <Route path="/" element={<TruckList/>} />
                <Route path="/trucks/*" element={<Truck/>} />
                <Route path="/map" element={<Map/>} />

                {/*Payment Imports*/}
                <Route path="/payment/order/*" element={<PaymentPage/>} />
                <Route path="/payment/custom/*" element={<PaymentPage/>} />
                <Route path="/payment/confirmation" element = {<PaymentPage />} />
                <Route path="/payment/completion" element = {<PaymentPage />} />
                
                {/*Landing Page Imports*/}
                <Route path='/home' element={<LandingPage />} />
                <Route path="/merchants" element={<MerchantLanding/>} />
                <Route path='/FAQ' element={<FAQ/>} />

                {/*Dashboard Imports*/}
                <Route path="/truck-dashboard/*" element = {
                    <ProtectedRoutes>
                        <TruckDashboard />
                    </ProtectedRoutes>
                }/>
                <Route path="/owner-dashboard" element = {
                    <ProtectedRoutes>
                        <OwnerDashboard />
                    </ProtectedRoutes>
                }/>
                <Route path="/owner-dashboard/add-truck" element = {
                    <ProtectedRoutes>
                        <AddTruck />
                    </ProtectedRoutes>
                }/>

                {/*Account Setup Imports*/}
                <Route path="/user/account-type/*" element = {
                    <ProtectedRoutes>
                        <AccountType />
                    </ProtectedRoutes>
                }/>
                <Route path="/user/stripe-connect" element = {
                    <ProtectedRoutes>
                        <StripeConnect />
                    </ProtectedRoutes>
                }/>
                <Route path="/user/set-truck-info/*" element = {
                    <ProtectedRoutes>
                        <SetTruckInfo />
                    </ProtectedRoutes>
                }/>
                <Route path="/user/create-menu/*" element = {
                    <ProtectedRoutes>
                        <CreateMenu />
                    </ProtectedRoutes>
                }/>
                <Route path="/user/next-steps" element = {
                    <ProtectedRoutes>
                        <NextSteps />
                    </ProtectedRoutes>
                }/>
                <Route path="/user/refresh" element = {
                    <ProtectedRoutes>
                        <RefreshPage />
                    </ProtectedRoutes>
                }/>
                <Route path='/user/details-not-submitted' element={
                    <ProtectedRoutes>
                        <DetailsNotSubmitted />
                    </ProtectedRoutes>
                } />
                <Route path="/user/completion/*" element = {
                    <ProtectedRoutes>
                        <Completion />
                    </ProtectedRoutes>
                }/>

                <Route path="/register-truck" element={<Register/>} />
                <Route path="/register-employee/*" element={<RegisterEmployee/>} />
                <Route path="/owner-login" element={<OwnerLogin/>} />
                <Route path="/employee-login/*" element={<EmployeeLogin/>} />
                <Route path="/forgot-password" element={<ForgotPassword/>} /> 

                <Route path="*" element={<NotFound/>} />

            </Routes>
        </Router>
        </AuthProvider>
    )} else {
        return(
        <AuthProvider>
            <Router>
                <Routes>
                {/*Landing Page Imports*/}
                <Route path='/' element={<LandingPage />} />
                <Route path='/home' element={<LandingPage />} />
                <Route path="/merchants" element={<MerchantLanding/>} />
                <Route path='/FAQ' element={<FAQ/>} />

                {/*Dashboard Imports*/}
                <Route path="/truck-dashboard/*" element = {
                    <ProtectedRoutes>
                        <TruckDashboard />
                    </ProtectedRoutes>
                }/>
                <Route path="/owner-dashboard" element = {
                    <ProtectedRoutes>
                        <OwnerDashboard />
                    </ProtectedRoutes>
                }/>
                <Route path="/owner-dashboard/add-truck" element = {
                    <ProtectedRoutes>
                        <AddTruck />
                    </ProtectedRoutes>
                } />

                {/*Account Setup Imports*/}
                <Route path="/user/account-type/*" element={
                    <ProtectedRoutes>
                        <AccountType />
                    </ProtectedRoutes>
                } />
                <Route path="/user/stripe-connect" element = {
                    <ProtectedRoutes>
                        <StripeConnect />
                    </ProtectedRoutes>
                }/>
                <Route path="/user/set-truck-info/*" element = {
                    <ProtectedRoutes>
                        <SetTruckInfo />
                    </ProtectedRoutes>
                }/>
                <Route path="/user/create-menu/*" element = {
                    <ProtectedRoutes>
                        <CreateMenu />
                    </ProtectedRoutes>
                }/>
                <Route path="/user/next-steps/*" element = {
                    <ProtectedRoutes>
                        <NextSteps />
                    </ProtectedRoutes>
                }/>
                <Route path="/user/refresh" element = {
                    <ProtectedRoutes>
                        <RefreshPage />
                    </ProtectedRoutes>
                }/>
                <Route path='/user/details-not-submitted' element={
                    <ProtectedRoutes>
                        <DetailsNotSubmitted />
                    </ProtectedRoutes>
                } />
                <Route path="/user/completion/*" element = {
                    <ProtectedRoutes>
                        <Completion />
                    </ProtectedRoutes>
                }/>

                <Route path="/register-truck/" element={<Register/>} />
                <Route path="/owner-login" element={<OwnerLogin/>} />
                <Route path='/register-employee/*' element={<RegisterEmployee />} />
                <Route path="/employee-login/*" element={<EmployeeLogin/>} />
                <Route path="/forgot-password" element={<ForgotPassword/>} /> 

                <Route path="*" element={<NotFound/>} />

                </Routes>
            </Router>
        </AuthProvider>
        )
    }
}

export default App;
