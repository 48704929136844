import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';

import '../Styles/BurgerMenu.css'

export default function MerchantMenu() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
            <ListItemButton>
                <ListItemIcon>
                    <a href="/" >
                        <h3>
                            Home 
                        </h3>
                    </a>
                </ListItemIcon>
            </ListItemButton>
        </ListItem>
        <ListItem>
            <ListItemButton>
                <ListItemIcon>
                    <a href="/owner-login" >
                        <h3 >
                            Owner Login
                        </h3>
                    </a>
                </ListItemIcon>
            </ListItemButton>
        </ListItem>
        <ListItem>
            <ListItemButton>
                <ListItemIcon>
                    <a href="/employee-login" >
                        <h3 >
                            Employee Login
                        </h3>
                    </a>
                </ListItemIcon>
            </ListItemButton>
        </ListItem>
        <ListItem>
            <ListItemButton>
                <ListItemIcon>
                    <a href="/register-truck" >
                        <h3>
                            Register your truck
                        </h3>
                    </a>
                </ListItemIcon>
            </ListItemButton>
        </ListItem>
        </List>
      <Divider />
    </Box>
  );

  const anchor = 'left';

  const MenuIconStyles = {
    top: '0',
    left: '0',
    fontSize: '2rem',
    color: 'black',
    cursor: 'pointer',
    padding: '1rem',
    zIndex: '10',
    }

  return (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <MenuIcon style={MenuIconStyles}/>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
  );
}


