import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'

import {auth, db, update, ref, get, DBUserRoute, DBTruckRoute} from '../../../Config/firebase'

import OwnerNavbar from '../../../Components/Navbars/OwnerNavbar'

import {checkIfAccountConnected} from '../../../Config/functions'

import OnboardingNavbar from '../../../Components/Navbars/OnboardingNavbar'
import DashboardNavbar from '../../../Components/Navbars/DashboardNavbar'

import LoadingScreen from '../../../Components/LoadingScreen'

import {Box, Grid, Typography, Button} from '@mui/material'

import { uid } from 'uid'

function OwnerDashboard() {

    const id = auth.currentUser.uid

    const navigate = useNavigate()

    const userRef = ref(db, `${DBUserRoute}/Owners/${id}`)

    const checkIfOwner = async() => {
        const user = await get(userRef)
        const userData = user.val()
        if(userData === null){
            navigate('/employee-login')
        }
    }

    const [loading, setLoading] = useState(true)

    const [truckIDArray, setTruckIDArray] = useState([])

    const [trucks, setTrucks] = useState([{}])

    const [stripeID, setStripeID] = useState(null)

    const [noTrucks, setNoTrucks] = useState(null)

    const [stripeAccountConfirmed, setStripeAccountConfirmed] = useState(true)

    const getAccount = async() => {
        setLoading(true)
        const account = await get(userRef)
        const accountData = account.val()
        const stripeId = accountData.stripeID
        setStripeID(stripeId)
        const stripeAccData = await checkIfAccountConnected(stripeId)
        if(stripeAccData.data.account.charges_enabled){
            setStripeAccountConfirmed(true)
            update(userRef, {
                stripeAccountConfirmed: true
            })
        } else {
            update(userRef, {
                stripeAccountConfirmed: false
            })
            setStripeAccountConfirmed(false)
        }
        if(stripeAccData.data.account.details_submitted){
            update(userRef, {
                stripeDetailsSubmitted: true
            })
        } else{
            update(userRef, {
                stripeDetailsSubmitted: false
            }).then(() => {
                navigate('/user/refresh-page')
            })
        } 
        const truckIDs = accountData.trucks
        console.log(truckIDs)
        setTruckIDArray(truckIDs)
        if(truckIDs === undefined){
            console.log('no trucks')
            setNoTrucks(true)
            setLoading(false)
        } else {
            setNoTrucks(false)
            truckIDs.forEach(async(truckID) => {
                const truckRef = ref(db, `${DBTruckRoute}/${truckID}`)
                const truck = await get(truckRef)
                const truckData = truck.val()
                setTrucks((prev) => [...prev, truckData])
                setLoading(false)
            })
        }
    }

    const unPublishTruck = async(truckID) => {
        const truckRef = ref(db, `${DBTruckRoute}/${truckID}`)
        update(truckRef, {
            published: false
        })
    }

    useEffect(() => {
        getAccount()
        checkIfOwner()
    }, [])

    useEffect(() => {
        if(trucks){
            if(trucks.length > truckIDArray.length){
            const diff = truckIDArray.length - trucks.length
            for(let i = 0; i < diff; i++){
                trucks.shift()
            }
        }}
    }, [trucks])

    if(loading){
        return(
            <LoadingScreen />
        )
    }


    if(!stripeAccountConfirmed){
        return(
            <>
                <OnboardingNavbar />
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, textAlign: 'center', height: '100vh', backgroundColor: '#f5f5f5'}}>
                    <Typography variant="h4" sx={{marginBottom: 2}}>
                        Looks like your Stripe account isn't confirmed yet
                    </Typography>
                    <Typography variant="h6">
                        Please check back here when Stripe confirms your account. This shouldnt take more than 24-48 hours.
                    </Typography>
                    <br />
                    <Typography variant='h6'>
                        After that is done, this page will change and you will be able to add trucks, employees and everything else
                    </Typography>
                    <br />
                    <Typography variant='body1'>
                        If you have any questions, please contact us at admin@trucktab.co
                    </Typography>

                </Box>
            </>
        )
    } else {
        if(noTrucks && stripeAccountConfirmed){
        return(
            <>
                <OnboardingNavbar />
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, textAlign: 'center', height: '100vh', backgroundColor: '#f5f5f5'}}>
                    <Typography variant="h4" sx={{marginBottom: 2}}>
                        Looks like you haven't set up any trucks yet
                    </Typography>
                    <Typography variant="body1">
                        To start using TruckTab, you need to add a truck
                    </Typography>
                    <Typography variant='body1'>
                        Because you are an owner, you have two choices:
                    </Typography>
                    <Typography variant='body1'>
                        1. If you operate your truck, you can add it yourself (create the menu, add address and photos)
                    </Typography>
                    <Typography variant='body1'>
                        2. If you own/manage the truck, you can send a custom link to your employees who will create the menu and add all the information
                    </Typography>
                    <br />
                    <Typography variant='body1'>
                        If you choose the second option, you can copy the link below and send it to your employees. After they set up the account, you will be able to see all info here
                    </Typography>
                    <br />
                    <br />
                    <Button variant='contained' color='primary' style={{
                        width: '50%',
                    }}onClick={(e) => {
                        navigate('/owner-dashboard/add-truck')
                    }}>
                        Add a truck
                    </Button>
                </Box>
            </>

        )
    } else if(!noTrucks && stripeAccountConfirmed){
        return(
        <>
            <OwnerNavbar />
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, textAlign: 'center', height: '100vh', backgroundColor: '#f5f5f5'}}>
                <Typography variant="h4" sx={{marginBottom: 2}}>
                    Welcome to your dashboard
                </Typography>
                <Typography variant="body1">
                    Here you can view your trucks and their status
                </Typography>
                <br />
                <Grid container spacing={2}>
                    {trucks.map((truck) => {
                        const keyId = uid()
                        const employees = truck.employees
                        const truckID = truck.truckID
                        const truckName = truck.truckName
                        const setupType = truck.setupType
                        if(truckID){
                        return(
                            <Grid item xs={12} sm={6} md={4} lg={3} style={{
                                border: '1px solid #e0e0e0',
                                borderRadius: 5,
                            }} key={keyId}>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, textAlign: 'center', backgroundColor: '#f5f5f5'}}>
                                    <Typography variant="h6" sx={{marginBottom: 2}}>
                                        Name: {truckName}
                                    </Typography>
                                    {employees &&
                                    <Typography variant="body1">
                                        Employees: {employees ? Object.keys(employees).length : 0}
                                    </Typography>}
                                    <br />
                                    <Typography variant="body1">
                                        Menu created: {truck.menuCreated ? 'Yes' : 'No'}
                                    </Typography>
                                    <br />
                                    <Typography variant="body1">
                                        Info uploaded: {truck.infoUploaded ? 'Yes' : 'No'}
                                    </Typography>
                                    <br />
                                    {(setupType === 'owner' && !truck.menuCreated) &&
                                    <Button variant='contained' onClick={() => {navigate(`/user/create-menu/${truckID}`)}} color='primary'>
                                        Create menu
                                    </Button>}
                                    <br />
                                    {(setupType === 'owner' && !truck.infoUploaded) &&
                                    <Button variant= 'contained' onClick={() => {navigate(`/user/set-truck-info/${truckID}`)}}>
                                        Upload info
                                    </Button>}
                                    {(truck.menuCreated && truck.infoUploaded && !truck.published) && 
                                        <>
                                        <Typography variant="body1">
                                            All info uploaded, but not published.
                                        </Typography>
                                        <br />
                                        <Button variant='contained' color='primary' onClick={(e) => {
                                            navigate(`/user/completion/${truckID}`)
                                        }}>
                                            Publish truck
                                        </Button>
                                        </>
                                    }
                                    {truck.published &&
                                    <>
                                    <Button variant='contained' color='primary' onClick={(e) => {
                                        navigate(`/truck-dashboard/${truckID}`)
                                    }}>
                                        View truck dashboard
                                    </Button>
                                    <br />
                                    <Button variant='contained' color='secondary' onClick={(e) => {
                                        unPublishTruck(truckID)
                                    }}>
                                        Unpublish truck
                                    </Button>
                                    </>
                                    }
                                </Box>
                            </Grid>
                        )}
                    })}
                </Grid>
            </Box>
        </>
        )
    }}
}

export default OwnerDashboard