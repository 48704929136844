import React from 'react'

import OnboardingNavbar from '../../Components/Navbars/OnboardingNavbar'

import {Box, Grid, Typography, Button} from '@mui/material'

function DetailsNotSubmitted() {
    return (
        <>
        <OnboardingNavbar />
        <br />
        <Box>
            <br />
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Typography variant="h4" fontWeight="500">Looks like you haven't submitted your details to Stripe!</Typography>
            </Grid>
            <br />
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Typography variant="h5" fontWeight="500">Please click the button below to continue</Typography>
            </Grid>
            <br />
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Typography variant="h5" fontWeight="500">You will be taken back to Stripe to complete your account. Once you are done, please login again again and set up the remaining parts of your account.</Typography>
            </Grid>
            <br />
            <br />
            <Grid container justifyContent='center'>
                <Button variant='contained' color='primary' href='https://stripe.com/login'>
                    Continue
                </Button>
            </Grid>

        </Box>
        </>
    )
}

export default DetailsNotSubmitted