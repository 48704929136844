import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";

import { useAuth } from './Context/authContext';
import { auth, db, get, ref, DBUserRoute } from '../../Config/firebase';

import LandingNavbar from '../../Components/Navbars/LandingNavbar';

import './Styles/Login.css';

function OwnerLogin () {
    const [user, setUser] = useState({
      email: "",
      password: "",
    });

    const [error, setError] = useState();

    const { login, googleLogin } = useAuth();

    // login function
    const checkIfEmailVerified = async () => {
      try{
        await login(user.email, user.password)
        const authUser = auth.currentUser
        if(authUser.emailVerified){
            const ownerRef = ref(db, `${DBUserRoute}/Owners/${authUser.uid}`)
            get(ownerRef).then((snapshot) => {
              if(snapshot.val()){
                navigate("/owner-dashboard");
              } else {
                navigate('/user/account-type')
              }
            })
        } else {
          setError('Please verify your email address')
        }
      } catch(error){
        if(error.message === 'Firebase: Error (auth/user-not-found).'){
          setError('Invalid email or password')
        } else if(error.message === 'FirebaseError: Firebase: Error (auth/wrong-password).'){
          setError('Incorrect password')
        } else if(error.message === 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).'){
          setError('Too many login attempts. Please try again later.')
        } else {
          setError('Invalid email or password')
        }
      }
    };

    const navigateToForgotPassword = () => {
      navigate("/forgot-password");
    };
    
    const navigate = useNavigate();
  
    const handleSubmit = (e) => {
      e.preventDefault();
      try {
        checkIfEmailVerified()
      } catch (error) {
        console.log(error)
      }
    };
  
    const handleGoogleLogin = async () => {
      await googleLogin();
      navigate("user/api/check");
    };
  
    return (
      <>
        <LandingNavbar />
        <div className="LoginPage">
                <div className="LoginPage_Form">
                    <h1>Owner Login</h1>
                    <form onSubmit={handleSubmit}>
                      <label>Email</label>
                      <input
                          type="email"
                          name="email"
                          id="email"
                          value={user.email}
                          onChange={(e) => setUser({ ...user, email: e.target.value })}
                          required/>
                      <p></p>
                      <label>Password</label>
                      <input
                          type="password"
                          name="password"
                          id="password"
                          value={user.password}
                          onChange={(e) => setUser({ ...user, password: e.target.value })}
                          required/>

                          <button id="login_button" type="submit">Login</button>

                          {error && <p style={{
                            color: 'red',
                          }}>{error}</p>}
                    </form>
                    <div className='Buttons'>
                      <button className = 'BottomButtons'onClick={navigateToForgotPassword}>
                        Forgot Password?
                      </button>
                      <button className='BottomButtons' onClick={handleGoogleLogin}>Login with Google</button>
                    </div>
                    
                </div>
              </div>
      </>
    );
  }

export default OwnerLogin;