import React from 'react';
import { Container, Box, Typography, Grid, Stack, Button } from '@mui/material';
import LandingNavbar from '../../../Components/Navbars/MerchantNavbar';

const MerchantLanding = () => {
  return (
    <>
      <LandingNavbar />
      <Container maxWidth="md" className="container">
        <br />

        <Box sx={{ textAlign: 'left', py: 4 }}>
          <Typography variant="h4" fontWeight="500">Join the newest name in payments for food trucks</Typography>
          <br />
          <Typography variant="h5" fontWeight="300">Create your account and start boosting revenue.</Typography>
          <br />
          <Typography variant="h5" fontWeight="300">No app needed for you or for customers</Typography>
        </Box>
        <Box sx={{ textAlign: 'center', py: 4 }}>
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Button variant="contained" size="large" href="/register-truck" sx={{ backgroundColor: 'primary.main', color: 'white' }}>Get Started</Button>
        </Box>
        <br />
          <Typography variant="h4" fontWeight="500">Our features: </Typography>
          <br />
          <br />
        </Box>
        <Box>
          <Stack container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center', py: 2 }}>
                <img src={require('../../../Assets/Images/noticed.png')} alt = '' style={{
                  width: '100%',
                }}/>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center', py: 2 }}>
                <img src={require('../../../Assets/Images/boost.png')} alt = '' style={{
                  width: '100%',
                }}/>
              <Button style={{
                backgroundColor: '#FFA500',
                width: '50%',
                align: 'center',
                fontSize: '16px',
              }} href ='/register-truck'>Join now!</Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center', py: 2 }}>
                <img src={require('../../../Assets/Images/menuslide.png')} alt = '' style={{
                  width: '100%',
                }}/>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center', py: 2 }}>
                <img src={require('../../../Assets/Images/hardware.png')} alt = '' style={{
                  width: '100%',
                }}/>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center', py: 2 }}>
                <img src={require('../../../Assets/Images/privacy.png')} alt = '' style={{
                  width: '100%',
                  objectFit: 'cover',
                }}/>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center', py: 2 }}>
                <img src={require('../../../Assets/Images/pricing.png')} alt = '' style={{
                  width: '100%',
                  objectFit: 'cover',
                }}/>
              </Box>
            </Grid>
          </Stack>
        </Box>
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography variant="h4" fontWeight="500">Why TruckTab?</Typography>

          <br />
          <Typography variant='h6' align='left'>
             - We provide a simple, secure, and affordable way for food trucks to accept payments
          </Typography>
          <br />
          <Typography variant='h6' align='left'>
             - There's no fees and only a 1% commission on our end
          </Typography>
          <br />
          <Typography variant='h6' align='left'>
             - Money goes straight from customer into your bank account in 24-48 hours!
          </Typography>
          <br />
          <Typography variant='h6' align='left'>
             - You get an individual customer representative who is on call 24/7
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center', py: 2 }}>
          <Typography variant="h4" fontWeight="500">What now?</Typography>
          <br />
          <Typography variant='h5' align='center'>
             - Sign up for a free account and start accepting payments within 48h. Click the link below to get Started
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Button variant="contained" size="large" href="/register-truck" sx={{ backgroundColor: 'primary.main', color: 'white' }}>Get Started</Button>
        </Box>
        <Box sx={{ textAlign: 'left', py: 4 }}>
          <Typography variant="h5" fontWeight="500">Contact Us</Typography>
          <br />
          <Typography variant='h6' align='left'>
          If you have any questions, email us at:
            {' '}
            <a href="mailto:
              admin@trucktab.co
            ">
              admin@trucktab.co
            </a>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default MerchantLanding;
