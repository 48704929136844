import React, {useState} from 'react';

import { sendEmailVerification } from 'firebase/auth';

import { useAuth } from './Context/authContext';
import { Alert } from '../../Components/Alert'

import { Modal } from '@mui/material';
import { Button } from '@mui/material';

import MerchantNavbar from '../../Components/Navbars/MerchantNavbar';

import './Styles/Register.css'

function Register () {

    const { signUp } = useAuth();

    const [error, setError] = useState({
        username: '',
        password: '',
        confirmPassword: '',
    });
    const [userError, setUserError] = useState('')
    const [userCredentials, setUserCredentials] = useState({
        email: '',
        password: '',
        confirmPassword: '',
    })

    const [show, setShow ] = useState(false);

    const onInputChange = e => {
        const { name, value } = e.target;
        setUserCredentials(prev => ({
          ...prev,
          [name]: value
        }));
        validateInput(e);
    }

    const validateInput = e => {
        let { name, value } = e.target;
        setError(prev => {
          const stateObj = { ...prev, [name]: "" };
     
          switch (name) {
            case "username":
              if (!value) {
                stateObj[name] = "Please enter Email.";
              }
              break;
     
            case "password":
              if (!value) {
                stateObj[name] = "Please enter Password.";
              } else if (userCredentials.confirmPassword && value !== userCredentials.confirmPassword) {
                stateObj["confirmPassword"] = "Password and Confirm Password does not match.";
              } else {
                stateObj["confirmPassword"] = userCredentials.confirmPassword ? "" : error.confirmPassword;
              }
              break;
     
            case "confirmPassword":
              if (!value) {
                stateObj[name] = "Please Confirm Password.";
              } else if (userCredentials.password && value !== userCredentials.password) {
                stateObj[name] = "Password and Confirm Password does not match.";
              }
              break;
     
            default:
              break;
          }
     
          return stateObj;
        });
    }


    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await signUp(userCredentials.email, userCredentials.password).then((userCredential) => {
          const user = userCredential.user;
          sendEmailVerification(user)
          setShow(true);
        })
      } catch (err) {
        setUserError(err.message);
        console.log(err.message)
      }
    }

    const ModalStyle = {
      // center page and white background 
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      width: 300,
      height: 200,
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius: 15,
    };

    const ButtonStyle = {
      // center button at bottom of modal
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      width: 75,
      height: 30,
      border: '1px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius: 5,
    };

    return (
        <>
        <MerchantNavbar />
        <Modal
          open={show}
          onClose={() => setShow(false)}>
          <div style={ModalStyle} id="RegisterPage_Modal">
            <h2>Email Verification</h2>
            <p id="RegisterPage_Modal_Text">
              A verification email has been sent to your email address. Please verify your email address to continue.
            </p>
            <a href="/owner-login">
              <Button style = {ButtonStyle}>Okay</Button>
            </a>
          </div>
        </Modal>
        <header className='header'>
            Enter Your Details
        </header>
        <div className='RegisterPage'>
          <h3>Only owners can register their truck as there is a verification process</h3>
            <div className='RegisterPage_Container'>
                <div className='RegisterPage_FormContainer'>
                {userError && <Alert message={userError} />}
                    <form onSubmit={handleSubmit} className='RegisterPage_Form'>
                        <label> Email *</label>
                        <input type='email' name='email' required onChange={onInputChange} onBlur={validateInput}/>
                        {error.username && <span className='err'>{error.username}</span>}

                        <label> Password *</label>
                        <input type='password' name='password' required onChange={onInputChange} onBlur={validateInput}/>
                        {error.password && <span className='err'>{error.password}</span>}

                        <label> Confirm Password *</label>
                        <input type='password' name='confirmPassword' required onChange={onInputChange} onBlur={validateInput}/>
                        {error.confirmPassword && <span className='err'>{error.confirmPassword}</span>}
                        <button id="register_button" type='submit'>Register</button>
                    </form>
                </div>
                <br />
            </div>
        </div>
        </>
    );
}

export default Register;