// Loading Screen Component
//
// Path: WebApp/src/App/Components/LoadingScreen.jsx

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function LoadingScreen() {

    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
    }

    return (
        <Box style = {style}>
            <CircularProgress />
        </Box>

    );
}
