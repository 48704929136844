import React from 'react';

import {Box, Button, Grid, Typography} from '@mui/material'

import LandingNavbar from '../../../Components/Navbars/LandingNavbar';

import '../Styles/FAQ.css'

function FAQ() {
    return (
        <>
        <LandingNavbar/>
            <div className='FAQ'>
                <div className='FAQContainer'> 
                    <Box sx={{ textAlign: 'center', py: 4 }}>
                        <Typography variant="h3" fontWeight="350">Frequently Asked Questions</Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ textAlign: 'center', py: 2 }}>
                                    <Typography variant="h5" fontWeight="350">What is TruckTab?</Typography>
                                    <Typography variant="h6" fontWeight="300">TruckTab is a mobile payment system for food trucks in NYC. We provide a simple and secure way for customers to pay for their food and for food trucks to accept payments.</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <Box sx={{ textAlign: 'center', py: 2 }}>
                                    <Typography variant="h5" fontWeight="350">How does TruckTab work?</Typography>
                                    <Typography variant="h6" fontWeight="300">You can pay via any payment method at your favourite food truck or order ahead through our Web App! No app download is required for customers or food trucks!</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ textAlign: 'center', py: 2 }}>
                                    <Typography variant="h5" fontWeight="350">How much does TruckTab cost?</Typography>
                                    <Typography variant="h6" fontWeight="300">We are unlike any other mobile order app! There are no fees for customers or trucks!</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ textAlign: 'center', py: 2 }}>
                                    <Typography variant="h5" fontWeight="350">How do I get started?</Typography>
                                    <Typography variant="h6" fontWeight="300">If you want to register your truck, head over to our 'For Merchants' section or click the button below.</Typography>
                                    <Button href='/merchants' style={{
                                        backgroundColor: '#FFA500',
                                        width: '50%',
                                        fontSize: '14px'
                                    }}>For Merchants</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ textAlign: 'center', py: 2 }}>
                                    <Typography variant="h5" fontWeight="350">I am a food truck owner; do I need to buy any hardware?</Typography>
                                    <Typography variant="h6" fontWeight="300">No! Everything is done via QR Codes and NFC Cards which we provide for free. All orders can be seen through our custom dashboard on any mobile device</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ textAlign: 'center', py: 2 }}>
                                    <Typography variant="h5" fontWeight="350">What happens if I don't want my food truck on your service anymore?</Typography>
                                    <Typography variant="h6" fontWeight="300">We would be very sad to see you go! But because there are no contracts, you simply click a button on your dashboard. You can turn it back on at any time</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        </>
    )
}

export default FAQ;