import React from 'react';
import { Container, Box, Typography, Grid, Stack, Button} from '@mui/material';
import LandingNavbar from '../../../Components/Navbars/LandingNavbar'

const LandingPage = () => {
  return (
    <>
      <LandingNavbar />
      <Container maxWidth="md" className="container">
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography variant="h3" fontWeight="350">Welcome to TruckTab</Typography>
          <Typography variant="h5" fontWeight="300">The newest name in payments and mobile orders for food trucks in NYC</Typography>
        </Box>
        
        <Box sx={{ textAlign: 'center', py: 4 }}>
        </Box>
        <Box>
          <Stack container spacing={2}>
            <Grid item xs={12} md={6}>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center', py: 2 }}>
                <img src={require('../../../Assets/Images/trucklanding.png')} alt = '' style={
                  {width: '100%'}
                }/>
                <Button style={{
                backgroundColor: '#FFA500',
                width: '50%',
              }} href ='/merchants'>For Merchants</Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center', py: 2 }}>
                <img src={require('../../../Assets/Images/nofees.png')} alt='' style={{
                  width: '100%'
                }}/>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center', py: 2 }}>
                <img src={require('../../../Assets/Images/nohardware.png')} alt ='' style={{
                  width: '100%'
                }}/>
                <Button style={{
                backgroundColor: '#FFA500',
                width: '50%'
              }} align= 'center' href ='/merchants'>For Merchants</Button>
              </Box>
            </Grid>
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default LandingPage;
