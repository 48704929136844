import React, { useEffect, useState } from 'react';

import { ref, get, update, db, auth, DBTruckRoute } from '../../../../../../Config/firebase';

function PendingOrders() {

    const date = new Date().toLocaleDateString()
    const year = date.split('/')[2]
    const day = date.split('/')[1]
    const month = date.split('/')[0]

    const id = auth.currentUser.uid;
    const orderRef = ref(db, `${DBTruckRoute}/${id}/orders/${year}/${month}/${day}`)
    const [ allOrders, setAllOrders ] = useState([{}])
    const [ allDone, setAllDone ] = useState(false)

    const getOrders = () => {
        get(orderRef).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val()
                setAllOrders(data)
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    const checkIfAllDone = () => {
        let allDone = true
        Object.keys(allOrders).map((orderID) => {
            const order = allOrders[orderID]
            if(order.status === 'Pending'){
                allDone = false
            } else if(order.status === 'RBT'){
                allDone = false
            }
        })
        setAllDone(allDone)
    }

    useEffect(() => {
        getOrders()
        checkIfAllDone();
    })

    const logAsRecieved = (orderID) => {
        // update as RBT in DB
        const orderRef = ref(db, `${DBTruckRoute}/${id}/orders/${year}/${month}/${day}/${orderID}`)
        update(orderRef, {
            status: 'RBT'
        })
    }

    const logAsCompleted = (orderID) => {
        // update as Completed in DB
        const orderRef = ref(db, `${DBTruckRoute}/${id}/orders/${year}/${month}/${day}/${orderID}`)
        update(orderRef, {
            status: 'Completed'
        })
    }

    return(
        <>
            <div className="PendingOrders">
                <div className="PendingOrder_Container">
                    <h2>Pending Orders</h2>
                    {allDone ? <p>All Done!</p> : null}
                    {Object.keys(allOrders).map((orderID) => {
                        const order = allOrders[orderID]
                        if(order.status === 'Pending'){
                            const orderItems = order.orderItems
                            return(
                                orderItems.map((item) => {
                                    let RBT = false
                                    return(
                                        <div className="PendingOrders_Item">
                                            <h3>Order ID: {order.orderID}</h3>
                                            <h3> Order Time: {order.orderTime} </h3>
                                            <h4>{item.name}</h4>
                                            {item.options &&
                                                item.options.map((option) => {
                                                    return(
                                                        <div className="PendingOrders_Item_Option">
                                                            <h5>{option}</h5>
                                                        </div>
                                                    )
                                                }
                                            )}
                                            {order.instructions ? undefined : <p>Instructions: {order.instructions}</p>}
                                            <button onClick = {() => {
                                                logAsRecieved(orderID)
                                                RBT = true
                                                window.location.reload();
                                            }}>
                                                {RBT ? <p>Customer Notified</p> : 'Log as Recieved'}
                                            </button>
                                        </div>
                                    )
                                })
                            )
                        } else if(order.status === 'RBT'){
                            const orderItems = order.orderItems
                            return(
                                orderItems.map((item) => {
                                    let Completed = false
                                    return(
                                        <div className="PendingOrders_Item">
                                            <h3>Order ID: {order.orderID}</h3>
                                            <h4>{item.name}</h4>
                                            {item.options &&
                                                item.options.map((option) => {
                                                    return(
                                                        <div className="PendingOrders_Item_Option">
                                                            <h5>{option}</h5>
                                                        </div>
                                                    )
                                                }
                                            )}
                                            {order.instructions ? undefined : <p>Instructions: {order.instructions}</p>}
                                            <p>Customer Notified</p>
                                            <button onClick = {() => {
                                                logAsCompleted(orderID)
                                                Completed = true
                                                window.location.reload();
                                            }} >
                                                {Completed ? <p>Order Completed</p> : 'Log as Completed'}
                                            </button>
                                        </div>
                                    )
                                })
                            )
                        } else {
                            return null
                        }
                    })}
                    {allDone ? <h4>No Pending Orders For Now</h4> : null}
                </div>
            </div>
            
        </>
    )

}

export default PendingOrders;