import React from 'react'

import OwnerMenu from '../BurgerMenu/OwnerMenu'

import '../Styles/Navbar.css'

function OwnerNavbar() {
    return (
        <div className= "Navbar">
            <div className="Navbar_Left">
                <OwnerMenu />
            </div>
            <div className="Navbar_Right">
                <a href="/" >
                    <img src = {require('../../Assets/Images/logo.png')} alt="Logo" />
                </a>
            </div>
        </div>
    )
}

export default OwnerNavbar