import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

import {Select, InputLabel, Typography, Box, Grid, TextField, Button, MenuItem} from '@mui/material'

import {auth, db, set, get, update, ref, DBUserRoute, DBTruckRoute} from '../../../Config/firebase'

import { checkIfAccountConnected } from '../../../Config/functions'

import OnboardingNavbar from '../../../Components/Navbars/OnboardingNavbar'
import LoadingScreen from '../../../Components/LoadingScreen'

function AccountType(){
    const id = auth.currentUser.uid

    const navigate = useNavigate()

    const [accountType, setAccountType] = useState('')

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const [accountTypeSelected, setAccountTypeSelected] = useState(false)

    const handleSubmit = async(e) => {
        try{
            setLoading(true)
            e.preventDefault()
            const ownerRef = ref(db, `${DBUserRoute}/Owners/${id}`)
            await set(ownerRef, {
                accountType: accountType,
                accountSetup: false,
                stripeDetailsSubmitted: false,
            }).then(() => {
                setLoading(false)
                navigate('/user/stripe-connect')
            })
        } catch {
            setError('Error storing information')
            setLoading(false)
        }
    }

    const handleChange = (e) => {
        setAccountType(e.target.value)
        setAccountTypeSelected(true)
    }

    if(loading){
        return(
            <LoadingScreen />
        )
    }

    return(
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <OnboardingNavbar />
            <Box sx={{ flexGrow: 1 }}>
                <br />
                <Grid container spacing={2}>
                    <br />
                    <Grid item xs={12}>
                        <br />
                        <Typography variant="h4" align='center' gutterBottom>
                            Select An Account Type
                        </Typography>
                    </Grid>
                    <br />
                    <Grid item xs={12} style={{
                        width: '100%'
                    }}>
                        <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={accountType}
                            label="Account Type"
                            style={{
                                width: '100%',
                            }}
                            onChange={e => handleChange(e)}>
                            <MenuItem value={'Owner'}>Owner (I own food trucks and my employees work there) </MenuItem>
                            <MenuItem value={'Owner&Operator'}>Owner and Operator (I work at my own food truck)</MenuItem>
                        </Select>
                    </Grid>
                    <br />
                    <br />
                    {error && <Typography variant="h6" align='center' gutterBottom style={{color: 'red'}}>
                        {error}
                    </Typography>}
                    <Grid item xs={12} style={{
                        width: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <br />
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!accountTypeSelected}
                            style={{
                                width: '50%',
                                alignItems: 'center',
                                backgroundColor: '#FFD700',
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: '18px'
                            }}>
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default AccountType

