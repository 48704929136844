import React, {useEffect, useState} from 'react'

import { useNavigate } from 'react-router-dom'

import {auth, db, ref, get, update, set, DBUserRoute, DBTruckRoute} from '../../../Config/firebase'
import { checkIfAccountConnected } from '../../../Config/functions'

import OwnerNavbar from '../../../Components/Navbars/OwnerNavbar'
import LoadingScreen from '../../../Components/LoadingScreen'

import {Box, Typography, Button, TextField} from '@mui/material'

import Modal from 'react-modal'

import {uid} from 'uid'

function AddTruck() {

    const truckID = uid(12)

    const id = auth.currentUser.uid

    const [stripeID, setStripeID] = useState()
    const [linkCopied, setLinkCopied] = useState()

    const [isOpen, setIsOpen] = useState(false)

    const[loading, setLoading] = useState(true)

    const [truckName, setTruckName] = useState('')

    const navigate = useNavigate()

    const userRef = ref(db, `${DBUserRoute}/Owners/${id}`)
    const truckRef = ref(db, `${DBTruckRoute}/${truckID}`)

    const copyLink = () => {
        navigator.clipboard.writeText(`https://www.trucktab.co/register-employee/${id}/${truckID}`)
        setLinkCopied(true)
    }

    const createTruckWithEmployee = async(e) => {
        get(userRef).then((snapshot) => {
            const trucks = snapshot.val().trucks
            if(trucks){
                const truckArray = trucks
                truckArray.push(truckID)
                update(userRef, {
                    trucks: truckArray
                })
            } else {
                update(userRef, {
                    trucks: [truckID]
                })
            }
        })
        set(truckRef, {
            truckName: truckName,
            truckID: truckID,
            ownerID: id,
            stripeID: stripeID
        }).then(async() => {
            copyLink()
            await update(truckRef, {
                setupType: 'employee',
            })
            setLinkCopied(true)
        })
    }

    const createTruckHimself = async(e) => {
        get(userRef).then((snapshot) => {
            const trucks = snapshot.val().trucks
            if(trucks){
                const truckArray = trucks
                truckArray.push(truckID)
                update(userRef, {
                    trucks: truckArray
                })
            } else {
                update(userRef, {
                    trucks: [truckID]
                })
            }
        })
        set(truckRef, {
            truckID: truckID,
            ownerID: id,
            stripeID: stripeID
        }).then(async() => {
            await update(truckRef, {
                setupType: 'owner',
            })
            navigate(`/user/set-truck-info/${truckID}`)
        })
    }

    useEffect(() => {
        setLoading(true)
        get(userRef).then((snapshot) => {
            const stripeId = snapshot.val().stripeID
            setStripeID(stripeId)
        }).then(() => {
            checkIfAccountConnected(stripeID).then((res) => {
                const chargesEnabled = res.data.account.charges_enabled
                if(!chargesEnabled){
                    navigate('/owner-dashboard')
                } else {
                    setLoading(false)
                }
            })
        })
    }, [])

    if(loading){
        return(
            <LoadingScreen />
        )
    }

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '500px',
            height: '80%',
            maxHeight: '500px',
            padding: '0px',
            borderRadius: '10px',
            border: 'none',
            backgroundColor: '#f5f5f5'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
    }

    return (
        <>
            <Modal style={modalStyles} isOpen={isOpen} ariaHideApp={false} onRequestClose={() => {setIsOpen(false)}}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, textAlign: 'center', height: '100%', backgroundColor: '#f5f5f5'}}>
                    <Typography variant="h4" sx={{marginBottom: 2}}>
                        Let's add a truck!
                    </Typography>
                    <Typography variant="body1" sx={{marginBottom: 2}}>
                        Enter the truck name and click the button to copy the link to send to your employee.
                    </Typography>
                    <TextField label='Truck Name' variant='outlined' sx={{marginBottom: 2}} onChange={(e) => {setTruckName(e.target.value)}} required/>
                    <Button variant='contained' color='primary' style={{
                        width: '50%',
                        maxWidth: '300px'
                    }} onClick={(e) => {createTruckWithEmployee(e);}}>
                        Copy Link
                    </Button>
                    <br />
                    {linkCopied && 
                        <Typography variant='body1' sx={{marginBottom: 2}}>
                            Link copied!
                        </Typography>
                    }
                    <Button variant='contained' color='primary' style={{
                        width: '50%',
                        maxWidth: '300px'
                    }} onClick={(e) => {setIsOpen(false)}}>
                        Cancel
                    </Button>
                </Box>
            </Modal>

            <OwnerNavbar />
            <Button variant='contained' color='primary' style={{
                    width: '20%',
                    maxWidth: '200px',
                    position: 'absolute',
                    backgroundColor: '#f5f5f5',
                    color: '#000000',
                }} onClick={() => {navigate(
                    '/owner-dashboard'
                )}}>
                    Back
                </Button>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, textAlign: 'center', height: '100vh', backgroundColor: '#f5f5f5'}}>
                <Typography variant="h4" sx={{marginBottom: 2}}>
                    Add a Truck
                </Typography>
                <Typography variant="body1" sx={{marginBottom: 2}}>
                    Add a truck to your account to start accepting orders
                </Typography>
                <Typography variant='body1' sx={{marginBottom: 2}}>
                    As an owner, you have 2 options:
                </Typography>
                <Typography variant='body1' sx={{marginBottom: 2}}>
                    1. If you operate and own the truck you can set it up yourself (create the menu, add the info)
                </Typography>
                <Typography variant='body1' sx={{marginBottom: 2}}>
                    2. If you are a manager or owner of a truck, you can add the truck to your account and have your employees set it up for you
                </Typography>
                <Typography variant='body1' sx={{marginBottom: 2}}>
                    If you are a manager or owner of a truck, please copy the link below and send it to your employees so they can set up the truck for you
                </Typography>
                <Button variant='contained' color='primary' style={{
                    width: '50%',
                    maxWidth: '300px'
                }} onClick={() => {setIsOpen(true)}}>
                    Send Link to Employees
                </Button>
                <br />
                {linkCopied ? <Typography variant='body1' sx={{marginBottom: 2}}>
                    Link Copied!
                </Typography> : null}
                < br />
                <Button variant='contained' color='primary' onClick = {() => {createTruckHimself();}} style={{
                    width: '50%',
                    maxWidth: '300px'
                }}>
                    Add Truck yourself
                </Button>
            </Box>
        </>

    )


}

export default AddTruck

