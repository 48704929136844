import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

import {Box, Grid, Typography, Button, TextField, Input} from '@mui/material'

import {auth, db, get, update, ref, DBTruckRoute, DBUserRoute, storage} from '../../../Config/firebase'
import {ref as stRef, getDownloadURL, uploadBytes} from 'firebase/storage'

import OnboardingNavbar from '../../../Components/Navbars/OnboardingNavbar'
import LoadingScreen from '../../../Components/LoadingScreen'

import '../Styles/SetTruckInfo.css'

function SetTruckInfo() {

    useEffect(() => {
        if(window.location.pathname.split('/')[4] === undefined){
            setTruckID(window.location.pathname.split('/')[3])
        } else {
            setTruckID(window.location.pathname.split('/')[4])
        }
        get(ref(db, `${DBTruckRoute}/${truckID}`)).then((snapshot) => {
            const data = snapshot.val()
            if(data.truckName){
                setTruckName(data.truckName)
                setTruckNameExists(true)
            } else {
                setTruckName('')
                setTruckNameExists(false)
            }
        })
        checkStatus()
    })

    const [isOwner, setIsOwner] = useState(false)
    const [isEmployee, setIsEmployee] = useState(false)
    const [truckNameExists, setTruckNameExists] = useState(false)

    const checkStatus = async() => {
        const ownerRef = ref(db, `${DBUserRoute}/Owners/${id}`)
        const employeeRef = ref(db, `${DBUserRoute}/Employees/${id}`)
        const isOwner  = await get(ownerRef)
        const isEmployee = await get(employeeRef)
        if(isOwner.exists()){
          setIsOwner(true)
        } else if(isEmployee.exists()){
          setIsEmployee(true)
        }
    }

    const id = auth.currentUser.uid

    const navigate = useNavigate()

    const [truckID, setTruckID] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const [preview, setPreview] = useState(null)

    const [truckName, setTruckName] = useState('')
    const [truckDescription, setTruckDescription] = useState('')
    const [truckAddress, setTruckAddress] = useState({
        street: '',
        city: '',
        state: '',
        zip: '',
    })
    const [truckPhoto, setTruckPhoto] = useState(null)

    const validateInfo = () => {
        if(truckName !== '' && truckAddress.street !== '' && truckAddress.city !== '' && truckAddress.state !== '' && truckAddress.zip !== '' && truckPhoto !== null){
            setCanSubmit(true)
        }
    }

    const handleTruckPhoto = (e) => {
        setTruckPhoto(e.target.files[0]);
        if(e.target.files[0].size > 1000000){
            setError('File size is too large');
            setCanSubmit(false);
        }
        if(e.target.files[0].type !=='image/jpeg' && e.target.files[0].type !== 'image/png'){
            setError('File type is not supported');
            setCanSubmit(false);
        }
        if(e.target.files[0] == null){
            setError('No file selected');
            setCanSubmit(false);
        }
    }

    const handleSubmit = (e) => {
        try{
            setLoading(true)
            const storageRef = stRef(storage, `${DBTruckRoute}/${truckID}/truck.png`);
            const uploadTask = uploadBytes(storageRef, truckPhoto);
            uploadTask.then((snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    update(ref(db, `${DBTruckRoute}/${truckID}/`), {
                        truckPhoto: downloadURL,
                        truckName: truckName,
                        address: truckAddress,
                        description: truckDescription,
                        infoUploaded: true,
                    });
                }).then(() => {
                    setLoading(false)
                    if(isOwner){
                        navigate('/owner-dashboard')
                    } else if(isEmployee){
                        navigate(`/user/next-steps/${truckID}`)
                    }
                });
            });
        } catch {
            setLoading(false)
            setError('Error storing information')
        }
    }
    
    useEffect(() => {
        validateInfo()
    }, [truckName, truckAddress, truckPhoto])

    useEffect(() => {
        if(!truckPhoto){
            setPreview(null)
        } else {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result)
            }
            reader.readAsDataURL(truckPhoto)
        }
    })

    if(loading){
        return(
            <LoadingScreen />
        )
    }

    return(
        <div>
            <OnboardingNavbar/>
            <br />
            <Box sx={{ flexGrow: 1 }}>
                <br />
                <div className='setTruckInfo'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="div" gutterBottom>
                            Truck Information {truckName && `for ${truckName}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" component="div" gutterBottom>
                            Please enter the following information about your truck
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" component="div" gutterBottom>
                            {error}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            preview &&
                            <div className='preview' style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <img src={preview} alt='Truck' className='truckPhoto' style={{
                                width: '60%',
                                maxWidth: '500px',
                                objectFit: 'cover',
                                borderRadius: '10px',
                            }}/>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            accept="image/*"
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={handleTruckPhoto}>
                        </Input>
                        <Typography variant='h6' >
                            {truckPhoto ? null : <Typography variant='subtitle1'>Please upload a photo of your truck</Typography>}
                        </Typography>
                        <br />
                    </Grid>
                    <div className='SetTruckInfo_Form' style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection:'column',
                        font: '14px'
                    }}>
                    {!truckNameExists &&
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Truck Name"
                            variant="outlined"
                            value={truckName}
                            style = {{
                                width: '80%',
                                maxWidth: '500px',
                            }}
                            onChange={e => setTruckName(e.target.value)}
                        />
                    </Grid>}
                    <Grid item xs={12}>
                        <TextField
                            id="multiline"
                            multiline
                            rows={4}
                            label="Give customers some insight on what you're about"
                            variant="outlined"
                            value={truckDescription}
                            style = {{
                                width: '80%',
                                maxWidth: '500px',
                            }}
                            onChange={e => setTruckDescription(e.target.value)}
                        />
                        <Typography variant='subtitle2'>
                            
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Street"
                            variant="outlined"
                            value={truckAddress.street}
                            style = {{
                                width: '80%',
                                maxWidth: '500px',
                            }}
                            onChange={e => setTruckAddress({...truckAddress, street: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="City"
                            variant="outlined"
                            value={truckAddress.city}
                            style = {{
                                width: '80%',
                                maxWidth: '500px',
                            }}
                            onChange={e => setTruckAddress({...truckAddress, city: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="State"
                            variant="outlined"
                            value={truckAddress.state}
                            style = {{
                                width: '80%',
                                maxWidth: '500px',
                            }}
                            onChange={e => setTruckAddress({...truckAddress, state: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Zip"
                            variant="outlined"
                            value={truckAddress.zip}
                            style = {{
                                width: '80%',
                                maxWidth: '500px',
                            }}
                            onChange={e => setTruckAddress({...truckAddress, zip: e.target.value})}
                        />
                    </Grid>
                    {error && <h6>{error}</h6>}
                    <Grid item xs={12}>
                        <Button variant="contained" disabled = {!canSubmit} onClick={handleSubmit}>Continue</Button>
                    </Grid>
                    </div>
                </Grid>
                </div>
            </Box>
        </div>
    )

}

export default SetTruckInfo

