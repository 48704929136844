import React, {useState, useEffect} from 'react'

import {Typography, Button, Grid, Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { get, ref, db, update, auth, DBUserRoute, DBTruckRoute } from '../../Config/firebase'

import OnboardingNavbar from '../../Components/Navbars/OnboardingNavbar'
import './Styles/Completion.css'

function Completion() {

    const authID = auth.currentUser.uid

    const navigate = useNavigate()

    const truckID = window.location.pathname.split('/')[3]

    const [ menu, setMenu ] = useState()
    const [ address, setAddress ] = useState({
        street: null,
        city: null,
        state: null,
        zip: null
    })
    const [ truckName, setTruckName ] = useState()
    const [ truckPhoto, setTruckPhoto ] = useState()
    const [ canSubmit, setCanSubmit ] = useState()

    useEffect(() => {
        const userRef = ref(db, `${DBTruckRoute}/${truckID}`)
        get(userRef).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val()
                setMenu(data.Menu)
                setAddress(data.address)
                setTruckName(data.truckName)
                setTruckPhoto(data.truckPhoto)
            } else {
                console.log("No data available");
            }
        })
    })

    useEffect(() => {
        if(truckName === undefined || address === undefined || truckPhoto === undefined || menu === undefined){
            setCanSubmit(false)
        } else {
            setCanSubmit(true)
        }
    }, [truckName, address, truckPhoto, menu])

    const publishTruck = () => {
        const truckRef = ref(db, `${DBTruckRoute}/${truckID}`)
        update(truckRef, {
            published: true
        }).then(() => {
            navigate('/owner-dashboard')
        })
    }

    return (
        <>
        <OnboardingNavbar />
        <div className="Completion">
            <Typography variant='h1' align='center'>
                Account Setup Complete!
            </Typography>
            <br></br>
            <Typography variant='h5' align='center'>
                You're all set to start accepting orders!
            </Typography>
            <br></br>
            <Typography variant='h5' align='center'>
                Please check your details below and if everything looks good to you, press the 'Publish Truck' button
            </Typography>
            <br></br>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant='h6' align='left'>
                            Address:
                        </Typography>
                        <Typography variant='h7' align='left'>
                            {address.street},
                            {address.city},
                            {address.state},
                            {address.zip}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant='h6' align='left'>
                            Truck Name: {truckName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant='h5' align='center'>
                            Truck Photo
                        </Typography>
                        <img src = {truckPhoto} alt=''/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant='h5' align='center'>
                            {canSubmit ? null : <h6 style={{
                                color: 'red'
                            }}>It looks like you havent submitted some details in Stripe. Head over to your 'Stripe Dashboard, Settings,  Public Details' and make sure your address and business name are filled in</h6>}
                        </Typography>
                    </Grid>
                </Grid>
                <br></br>
                <Button disabled = {!canSubmit} onClick = {
                    publishTruck
                } color = 'primary' variant = 'contained'>
                    Publish Truck
                </Button>
            </Container>
        </div>
        </>
    )
}

export default Completion