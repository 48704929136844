// Firebase Functions 
import {functions} from './firebase'
import { httpsCallable } from 'firebase/functions';

export let createPaymentIntent;
if (process.env.NODE_ENV === 'production') {
  createPaymentIntent = async(data) => {
    const createPaymentIntentFunction = httpsCallable(functions, "createPaymentIntent")
    return createPaymentIntentFunction(data);
  }
} else {
  createPaymentIntent = async(data) => {
    const createPaymentIntentFunction = httpsCallable(functions, "testCreatePaymentIntent")
    return createPaymentIntentFunction(data);
  }
}

export let createNewConnectAccount;
if (process.env.NODE_ENV === 'production') {
  createNewConnectAccount = async() => {
    const createNewConnectAccountFunction = httpsCallable(functions, "createNewConnectAccount")
    return createNewConnectAccountFunction();
  }
} else {
  createNewConnectAccount = async() => {
    const createNewConnectAccountFunction = httpsCallable(functions, "testCreateNewConnectAccount")
    return createNewConnectAccountFunction();
  }
}

export let checkIfAccountConnected;
if (process.env.NODE_ENV === 'production') {
  checkIfAccountConnected = async(stripeID) => {
    const checkIfAccountConnectedFunction = httpsCallable(functions, "checkIfAccountConnected")
    return checkIfAccountConnectedFunction(stripeID);
  }
} else {
  checkIfAccountConnected = async(stripeID) => {
    const checkIfAccountConnectedFunction = httpsCallable(functions, "testCheckIfAccountConnected")
    return checkIfAccountConnectedFunction(stripeID);
  }
}

export let sendEmployeeSignInLink;
if (process.env.NODE_ENV === 'production') {
  sendEmployeeSignInLink = async(data) => {
    const sendEmployeeSignInLinkFunction = httpsCallable(functions, "sendEmployeeSignInLink")
    return sendEmployeeSignInLinkFunction(data);
  }
} else {
  sendEmployeeSignInLink = async(data) => {
    const sendEmployeeSignInLinkFunction = httpsCallable(functions, "testSendEmployeeSignInLink")
    return sendEmployeeSignInLinkFunction(data);
  }
}

