import React, {useState, useEffect} from 'react';

import {db, ref, get, update, auth, DBTruckRoute, DBUserRoute} from '../../../../Config/firebase'

import DashboardNavbar from '../../../../Components/Navbars/DashboardNavbar';

import PendingOrders from './Details/Widgets/PendingOrders';

import '../Styles/Dashboard.css'
function TruckDashboard() {

  const id = auth.currentUser.uid;

  const [truckID, setTruckID] = useState();

  const [truckName, setTruckName] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const getTruckID = async() => {
    if(window.location.pathname.split('/')[2]){
      setTruckID(window.location.pathname.split('/')[2]);
    } else {
      const userRef = ref(db, `${DBUserRoute}/${id}`);
      await get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setTruckID(data.hasAccessTo);
        }
      })
    }
  }

  const getTruckInfo = async() => {
    const truckRef = ref(db, `${DBTruckRoute}/${truckID}`);
    await get(truckRef).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setTruckName(data.truckName);
        setIsOpen(data.isOpen);
      }
    })
  }

  useEffect(() => {
    getTruckID();
  }, [id])

  useEffect(() => {
    if(truckID){
      getTruckInfo();
    }
  }, [truckID])

  const toggleOpen = () => {
    const truckRef = ref(db, `${DBTruckRoute}/${truckID}`);
    update(truckRef, {
      isOpen: true
    })
    setIsOpen(true);
  }

  const toggleClosed = () => {
    const truckRef = ref(db, `${DBTruckRoute}/${truckID}`);
    update(truckRef, {
      isOpen: false
    })
    setIsOpen(false);
  }

    return (
      <>
        <DashboardNavbar />
        <div className="Dashboard">
          <h1>Dashboard for {truckName}</h1>
          <div className="IsOpen_Button">
            {isOpen ? (
              <button onClick={() => toggleClosed()}>Close down for the day</button>
            ) : (
              <button onClick={() => toggleOpen()}>Show users that you are open</button>
            )}
          </div>
          <br />
          <div className="NewOrders">
            <PendingOrders />
          </div>
        </div>
      </>
    );
  }

export default TruckDashboard;