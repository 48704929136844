
import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};


const app = initializeApp(firebaseConfig);

// Database
export const db = getDatabase(app);
export const get = require("firebase/database").get;
export const ref = require("firebase/database").ref;
export const child = require("firebase/database").child;
export const set = require("firebase/database").set;
export const onValue = require("firebase/database").onValue;
export const update = require("firebase/database").update;
export const DBTruckRoute = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_DB_TRUCKS_LIVE_ROUTE : process.env.REACT_APP_DB_TRUCKS_TEST_ROUTE;
export const DBUserRoute = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_DB_USERS_LIVE_ROUTE : process.env.REACT_APP_DB_USERS_TEST_ROUTE;

// Storage
export const storage = getStorage(app);
export const sRef = require("firebase/storage").ref;

// Auth
export const auth = getAuth(app)

// Functions 
export const functions = getFunctions(app);