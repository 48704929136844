import React from 'react';

import TruckMenu from './TruckMenu';
import TruckInfo from './TruckInfo';
import MenuItem from './MenuItem';

import OrderSummary from './OrderSummary'

function Truck() {

    const id = window.location.pathname.split('/')[2];
    const path = window.location.pathname.split('/')[3];
    const section = window.location.pathname.split('/')[4];
    const itemID = window.location.pathname.split('/')[5];
    

    if(path === 'menu' && itemID === undefined){
        return (
            <TruckMenu id={id} section={section} />
        )
    } if(path === 'main' && itemID === undefined){
        return (
            <TruckInfo id={id} />
        )
    } if(itemID !== undefined) {
        return(
            <MenuItem />
        )
    } if(path === 'order' && itemID === undefined){
        return(
            <OrderSummary />
        )
    }
    
    
}

export default Truck;