import React from 'react';

import LandingNavbar from '../../../Components/Navbars/LandingNavbar';

const NotFound = () => {
  return (
    <>
    <LandingNavbar />
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <div className="flex-grow"></div>
      <div className="container mx-auto max-w-2xl text-center py-8">
        <h1 className="text-4xl font-bold mb-4">Page Not Found - 404</h1>
        <p className="text-gray-500 mb-8">Oops! Looks like you're lost.</p>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => window.history.back()}
        >
          Go Back
        </button>
      </div>
      <div className="flex-grow"></div>
    </div>
    </>
  );
};

export default NotFound;
