import React, { useState } from 'react'
import { Button, Container, Typography } from '@mui/material';

import { createNewConnectAccount } from '../../../Config/functions';

import { auth, db, update, ref, DBUserRoute } from '../../../Config/firebase'

import OnboardingNavbar from '../../../Components/Navbars/OnboardingNavbar';

function StripeConnect() {

  const [ loading, setLoading ] = useState(false)


  const handleStripeConnect = () => {
    setLoading(true)
    createNewConnectAccount()
      .then((res) => {
        const stripeID = res.data.AccountID
        const url = res.data.AccountLink
        window.localStorage.setItem('stripeID', stripeID)
        const userRef = ref(db, `${DBUserRoute}/Owners/${auth.currentUser.uid}`)
        update(userRef, {
          stripeID: stripeID
        }).then(() => {
          window.location.href = url
        })

        })
      .catch((err) => {
        console.log(err);
      });
  };
  if(loading){
    return (
      <>
        <OnboardingNavbar />
        <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, textAlign: 'center', height: '100vh', backgroundColor: '#f5f5f5' }}>
          <br />
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            Loading...
          </Typography>
          <Typography variant="body1">
            Please wait while we redirect you to Stripe
          </Typography>
        </Container>
      </>
    )
  }

  return (
    <>
      <OnboardingNavbar />
      <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, textAlign: 'center', height: '100vh', backgroundColor: '#f5f5f5' }}>
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          Time to get some info 
        </Typography>
        <Typography variant="body1">
          To start using TruckTab, you need your account to our secure service provider Stripe
        </Typography>
        <Typography variant="body1">
          Please follow the instructions below to get started:
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          1. Click the button below to create your account with Stripe 
        </Typography>
        <Typography variant="body1">
          2. Enter your business information and bank account details
        </Typography>
        <Typography variant="body1">
          3. Once you have completed the process, you will be redirected back to TruckTab
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          If you have any questions, please contact us at
          {' admin@trucktab.co '}
          <a href="mailto:
            admin@trucktab.co
          ">
          </a>
        </Typography>
        <Button variant="contained" color="primary" sx={{ marginTop: 2 }} onClick={handleStripeConnect}>
          Connect with Stripe
        </Button>
      </Container>
    </>
  );
}

export default StripeConnect;
