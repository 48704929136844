import React, {useState} from 'react';

import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../Config/firebase';
import { Grid, Typography, TextField, Button } from '@mui/material'
import LandingNavbar from '../../Components/Navbars/LandingNavbar';
import './Styles/ForgotPassword.css'
function ForgotPassword() {
    const [email, setEmail] = useState()

    const [message, setMessage] = useState('')

    const handleSubmit = () => {
        try{
            sendPasswordResetEmail(auth, email)
            .then(() => {
                setMessage('Email has been sent')
            })
        } catch (error) {
            setMessage(error)
        }
    }

    return(
        <>
        <LandingNavbar />
        <div className = "ForgotPassword">
            <br />
            <Typography variant = 'h4'>
                Forgot Password
            </Typography>
            <br />
            <br />
            <Typography variant = 'h5'>
                Please enter your email below
            </Typography>
            <br />
            <Grid container spacing = {2} direction = "column" alignItems = "center" justifyContent = "center" className = "ForgotPasswordGrid">
                <Grid item>
                    <Typography variant = "h5">
                        We will send you a link to reset your password
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField label = "Email" variant = "outlined" onChange = {(e) => setEmail(e.target.value)} />
                </Grid>
                <Grid item>
                    <Button variant = "contained" onClick = {handleSubmit}>Submit</Button>
                </Grid>
                {message && 
                    <Grid item>
                        <Typography variant = "h5">
                            {message}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </div>
        </>
    )
}

export default ForgotPassword