import { Navigate } from "react-router-dom";
import { useAuth } from "../sections/Auth/Context/authContext";

import LoadingScreen from "./LoadingScreen";

export const ProtectedRoutes = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) return <LoadingScreen />;

  if (!user) return <Navigate to="/home" />;

  return <>{children}</>;
}