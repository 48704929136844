import React, {useEffect, useState} from 'react'

import { useNavigate } from 'react-router-dom'

import {Box, Grid, Button, Typography} from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import {auth, db, ref, get, update, set, DBUserRoute, DBTruckRoute} from '../../../Config/firebase'

import OnboardingNavbar from '../../../Components/Navbars/OnboardingNavbar'

import LoadingScreen from '../../../Components/LoadingScreen'

function NextSteps() {

    const id  = auth.currentUser.uid

    const navigate = useNavigate()

    const [truckID, setTruckID] = useState()
    const [parent, setParent] = useState()

    useEffect(() => {
        try{
            if(window.location.pathname.split('/')[3] !== undefined){
                setTruckID(window.location.pathname.split('/')[4])
                setParent(window.location.pathname.split('/')[3])
            } else {
                get(ref(db, `${DBUserRoute}/Employees/${id}`)).then((snapshot) => {
                    if(snapshot.exists()){
                        const data = snapshot.val()
                        setTruckID(data.truckID)
                        setParent(data.employeeOf)
                        if(!data.employeeOf){
                            update(userRef, {
                                employeeOf : window.location.pathname.split('/')[3]
                            })
                        }
                    } else {
                        setError('No truck exists with that ID.')
                    }
                    setLoading(false)
                }).catch((errr) => {
                    setError(errr)
                })
            }
        } catch (errr) {
            setError(errr)
        }
    }, [])

    const [menuCreated, setMenuCreated] = useState(false)
    const [infoUploaded, setInfoUploaded] = useState(false)
    const [isPublished, setIsPublished] = useState(false)

    const [truckName, setTruckName] = useState()

    const [tasksCompleted, setTasksCompleted] = useState()

    const [error, setError] = useState()

    const userRef = ref(db, `${DBUserRoute}/Employees/${id}`)
    const truckRef = ref(db, `${DBTruckRoute}/${truckID}`)

    const [accountSetupType, setAccountSetupType] = useState()

    const [loading, setLoading] = useState(true)

    const checkTruck = async() => {
        try{
            await get(truckRef).then((snapshot) => {
                if(snapshot.exists()){
                    const data = snapshot.val()
                    setAccountSetupType(data.setupType)
                    setTruckName(data.truckName)
                    setMenuCreated(data.menuCreated)
                    setInfoUploaded(data.infoUploaded)
                    setIsPublished(data.isPublished)
                    if(isPublished === true){
                        navigate(`/truck-dashboard/${truckID}`)
                    }
                } else {
                    setError('No truck exists with that ID.')
                }
                setLoading(false)
            }).catch((errr) => {
                setError(errr)
            })
            if(menuCreated === true && infoUploaded === true){
                setTasksCompleted(true)
                update(userRef, {
                    tasksCompleted: true
                })
            }
        } catch (errr) {
            setError(errr)
        }
    }

    useEffect(() => {
        checkTruck()
    }, [truckID])

    if(loading){
        return <LoadingScreen />
    }

    if(accountSetupType === 'employee'){
    return(
        <>
            <OnboardingNavbar />
            <br />
            <Typography variant='h4' sx={{fontWeight: 'bold'}} align='center'>
                Next Steps
            </Typography>
            <br />
            <br />
            <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={12} md={6}>
                    <Typography variant='h5' sx={{fontWeight: 'bold'}} align='center'>
                        The owner has asked you to set up the truck's menu and info.
                    </Typography>
                    {(!menuCreated || !infoUploaded) &&
                    <Box sx={{border: '1px solid #000', borderRadius: '10px', padding: '20px'}}>
                        <Typography variant='h5' sx={{fontWeight: 'bold'}} align='center'>
                            Tasks
                        </Typography>
                        <br />
                        <br />
                        <ul>
                            <a href={`/user/set-truck-info/${truckID}`} style={{textDecoration: 'none', color: '#000'}}>
                                <li style={{
                                    textDecoration: infoUploaded ? 'line-through' : 'none',
                                    color: infoUploaded ? '#000' : '#000',
                                    fontSize: '20px'
                                }}>
                                    Upload the truck's info <ArrowCircleRightIcon />
                                </li> {infoUploaded ? '✅' : '❌'}
                            </a>
                            <br />
                            <a href={`/user/create-menu/${truckID}`} style={{textDecoration: 'none', color: '#000'}}>
                            <li style={{
                                    textDecoration: menuCreated ? 'line-through' : 'none',
                                    color: menuCreated ? '#000' : '#000',
                                    fontSize: '20px'
                                }}>
                                    Create the truck's menu <ArrowCircleRightIcon />
                                </li> {menuCreated ? '✅' : '❌'}
                            </a>
                        </ul>
                    </Box>}
                    <br />
                    <br />
                    {(menuCreated && infoUploaded) &&
                    <Box sx={{border: '1px solid #000', borderRadius: '10px', padding: '20px'}}>
                        <Typography variant='h5' sx={{fontWeight: 'bold'}} align='center'>
                            You're all set!
                        </Typography>
                        <br />
                        <Typography variant='h6' align='center'>
                            You can now safely log out and come back later.
                        </Typography>
                        <br />
                        <Typography variant='h6' align='center'>
                            As soon as the owner publishes the truck you can access the dashboard
                        </Typography>
                        <br />
                        <br />
                    </Box>}
                </Grid>
            </Grid>
        </>
    )} else {
        <>
            <OnboardingNavbar />
            <br />
            <Typography variant='h4' sx={{fontWeight: 'bold'}} align='center'>
                Next Steps
            </Typography>
            <br />
            <br />
            <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={12} md={6}>
                    <Typography variant='h5' sx={{fontWeight: 'bold'}} align='center'>
                        The owner has already set up the truck's menu and info.
                    </Typography>
                    <br />
                    <Typography variant='body1' align='center'>
                        You can now access the dashboard and start taking orders.
                    </Typography>
                    <br />
                    <br />
                    <Button variant='contained' fullWidth onClick={() => navigate(`/truck-dashboard/${truckID}`)}>
                        Dashboard
                    </Button>
                </Grid>
            </Grid>
        </>
    }
}

export default NextSteps